<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <h3>بیمه‌های تامین اجتماعی</h3>
      </v-card-title>
      <mobile-text v-if="deviceType == 'mobile'" :title="''" />
      <v-card class="cards pa-3" v-else>
        <v-card-text>
          <div>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateFrom">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateFrom"
                    label=" تاریخ از "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateFrom"
                  element="dateFrom"
                  color="#00a7b7"
                /> </v-col
              ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateTo">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateTo"
                    label=" تاریخ تا "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateTo"
                  element="dateTo"
                  color="#00a7b7"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  class="primary-btn submit-btn"
                  style="float: right"
                  @click="getInsurances()"
                  :disabled="Busy"
                  >اعمال</v-btn
                >
              </v-col>
            </v-row>
            <hr />
            <v-row align-v="end">
              <v-col> </v-col>
              <v-col>
                <v-btn
                  style="float: left"
                  class="secondary-btn mb-2"
                  @click="$refs.renderedExcel.$el.click()"
                  :disabled="!Items.length"
                  >دریافت اکسل</v-btn
                >
                <vue-excel-xlsx
                  v-show="false"
                  ref="renderedExcel"
                  :data="Items"
                  :columns="excelFields"
                  :filename="
                    'لیست بیمه‌های تامین اجتماعی  ' + dateFrom + ' تا ' + dateTo
                  "
                  :sheetname="currentDate"
                >
                  دریافت اکسل
                </vue-excel-xlsx>
              </v-col>
            </v-row>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              filled
              rounded
              clearable
              class="text-right w-80 mb-2 search-input"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="Items"
              empty-text="در بازه زمانی انتخاب شده بیمه‌ای برای نمایش وجود ندارد"
              empty-filtered-text="در بازه زمانی انتخاب شده بیمه‌ای برای نمایش وجود ندارد"
              :filter="Filter"
              :busy="Busy"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell(taminInsuranceId)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(taminInsuranceStatus)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(patientName)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(patientLastName)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(prescriptionType)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(patientNatCode)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(patientType)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(financialStatus)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(date)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(place)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
const MobileText = () => import("@/components/MobileText");
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    MobileText,
  },
  data() {
    return {
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: false,
      Fields: [
        { key: "index", label: "#" },

        { key: "patientName", label: "نام بیمار" },
        { key: "patientLastName", label: "نام‌خانوادگی بیمار" },
        { key: "patientNatCode", label: "کد ملی" },
        { key: "taminInsuranceId", label: "شناسه" },
        { key: "taminInsuranceStatus", label: "وضعیت" },
        { key: "prescriptionType", label: "نوع نسخه" },
        { key: "patientType", label: "نوع بیمار" },
        { key: "place", label: "محل ثبت" },
        { key: "financialStatus", label: "وضعیت پرداخت" },
        { key: "date", label: "تاریخ و ساعت" },
      ],
      excelFields: [
        { field: "patientName", label: "نام بیمار" },
        { field: "patientLastName", label: "نام‌خانوادگی بیمار" },
        { field: "patientNatCode", label: "کد ملی" },
        { field: "taminInsuranceId", label: "شناسه" },
        { field: "taminInsuranceStatus", label: "وضعیت" },
        { field: "prescriptionType", label: "نوع نسخه" },
        { field: "patientType", label: "نوع بیمار" },
        { field: "place", label: "محل ثبت" },
        { field: "financialStatus", label: "وضعیت پرداخت" },
        { field: "date", label: "تاریخ و ساعت" },
      ],
      Items: [],
      role: "",
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      busyDocPercent: false,
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      edited: false,
      insuranceItemDialog: false,
      selected: {},
      selectedEdit: {},
    };
  },
  methods: {
    getInsurances() {
      //NOTE getting services info by start date and end date when page mount
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/insurances/exports/tamin",
          {
            startDate: this.dateFrom,
            endDate: this.dateTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },

  mounted() {
    this.role = localStorage.getItem("role");
    this.getInsurances();
  },
};
</script>
